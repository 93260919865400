<script>
import Layout from "../../layouts/main";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
import axios from "axios";

export default {

  components: {
    Layout,
    EasyDataTable
  },
  data() {
    return {
        approvedheaders:[
            { text: "Sr. No.", value: "sr" },
            { text: "Applicants Name ", value: "applicantsname" },
            { text: "Application No.", value: "number"},
            { text: "Approved By", value: "rejectby"},
            { text: "Status", value: "applicationtag"}
        ],
        approvedApplications: []
    }
  },
  mounted() {
    this.fetchData();
    // print('this is data',this.showdata())
  },
  
  methods: {
    fetchData() {
      const t = localStorage.getItem('accessToken');
      console.log('this token is valid',t)
      let arrList = [];
      axios.get('/applications/approved_applications/')
        .then((response) => {
          const user_data = response.data;
          console.log("this is for yes check", user_data);
          let responseList = response.data;
          responseList.forEach((item, index) => {
            let newItem = {
              sr: index + 1,
              applicantsname : `${item.section_a1 && item.section_a1.first_name || 'N/A'} ${item.section_a1 && item.section_a1.sur_name || ''}`,
              number: item.application_no,
              rejectby: item.rev,
              applicationtag: "buffering",
              // statusLog:item.status,
              // actionBtnUrl:"#",
            };
            // Push the transformed item to the existing array
            arrList.push(newItem);
          });
          console.log("NEW ARRAY DATa",arrList);
          this.approvedApplications = arrList;
        })

        .catch((error) => {
          console.error("NEW ARRAY DATa",error);
        });
    },
    }

};

</script>

<template>
  <Layout>
    <main class="py-4">
        <div class="row mb-3 align-items-center">
            <div class="col-md-6">
                <h2 class="font-size-22 text-theme-dark mb-0">Approved Applications</h2>
            </div>
            <div class="col-md-6">
                <div class="page-title-right">
                    <nav aria-label="breadcrumb" class="d-flex justify-content-md-end m-0">
                        <ol class="breadcrumb px-0">
                            <li class="breadcrumb-item">
                                <router-link class="" to="/staff_dashboard" target="_self">Dashboards</router-link>
                            </li>
                            <li class="breadcrumb-item active">
                                <span aria-current="location">Approved Application</span>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="card">
                <div class="card-body">
                    <EasyDataTable
                        :headers="approvedheaders"
                        :items="approvedApplications"
                        :search-value="searchValueTable"
                        border-cell
                        theme-color="#df6a0d"
                        :rows-per-page="10"
                        buttons-pagination
                        table-class-name="logs-table-style"
                    >
                    <template #item-applicationtag="">
                        <p class="approved-tag status-tag mb-0">Approved </p>
                    </template>
                    </EasyDataTable>
                </div>
            </div>
        </div>
    </main>
  </Layout>
</template>